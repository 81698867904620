@import './theme-color-variables';
* {
  font-size: 16px;
}

/* Prevents page or container bounce effect */
html {
  overscroll-behavior: none;
}

body {
  overscroll-behavior: none;
  scrollbar-color: var(--secondary-backgroundColor) rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}

#root {
  min-height: 100%;
  background: $business-background;
}

.full-width {
  width: 100% !important;
}

.old-primary-btn {
  background: $business-surface !important;
  color: $business-text-primary !important;
}

.old-secondary-btn {
  background: $business-primary !important;
  color: $business-surface !important;
}

.old-success-btn {
  background: $business-primary !important;
  color: $business-surface !important;
}

.old-h1 {
  font-family: 'Roboto-Bold' !important ;
  font-size: 1.25rem !important ;
  color: #3a4d8f;
  font-weight: 600;
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0em !important;
}

.old-h2 {
  font-family: 'Roboto-Bold' !important ;
  font-size: 1.125rem !important ;
  color: #3a4d8f;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  letter-spacing: 0em !important;
}

.old-h3 {
  font-family: 'Roboto-Medium' !important ;
  font-size: 1.25rem !important ;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0em !important;
}

.old-h4 {
  font-family: 'Roboto-Bold' !important ;
  font-size: 1rem !important ;
  color: #ffffff;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  letter-spacing: 0em !important;
}

.old-h5 {
  font-family: 'Roboto' !important ;
  font-size: 0.8rem !important;
  font-weight: 300 !important;
  color: #6a95cc !important;
  padding-top: 0.3rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0em !important;
}

.old-h6 {
  margin: 0 !important ;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.334em !important;
  color: #27348a !important;
}

.old-h3-typography {
  font-family: Roboto-Bold;
  font-size: 1.25rem;
  font-weight: 600;
}

.old-h4-typography {
  font-size: 1rem;
  font-family: Roboto-Bold;
  font-weight: 600;
}

.old-h5-typography {
  font-size: 0.875rem;
  font-family: Roboto-Bold;
  font-weight: 600;
}

.old-h6-typography {
  font-size: 0.875rem;
  line-height: 170%;
  font-weight: 500;
  color: #000000;
}

.old-body2-typography {
  letter-spacing: '0em';
  line-height: '1.5em';
  font-weight: 400;
  color: #3b4754;
}

.old-icon-btn-color {
  color: #3a4d8f;
}

.MuiIconButton-root {
  padding: 8px !important;
}

.MuiToolBar-root {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 48px;
  padding: 16px;
}

.MuiFab-root {
  border-radius: 24px !important;
}

.MuiToolbar-regular {
  min-height: 48px !important;
}

.MuiPaper-rounded {
  border-radius: 10px;
}
