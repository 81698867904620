// text
$textMain: var(--text-main);
$textLink: var(--text-link);

// primary
$primaryLight: var(--primary-light);
$primaryMain: var(--primary-main);
$primaryDark: var(--primary-dark);
$primaryContrastText: var(--primary-contrastText);
$primaryHighlight: var(--primary-highlight);
$primaryDisabledButtonText: var(--primary-disabledButtonText);
$primaryBackgroundColor: var(--primary-backgroundColor);

// secondary
$secondaryLight: var(--secondary-light);
$secondaryMain: var(--secondary-main);
$secondaryDark: var(--secondary-dark);
$secondaryContrastText: var(--secondary-contrastText);
$secondaryHighlight: var(--secondary-highlight);
$secondaryDisabledButtonText: var(--secondary-disabledButtonText);
$secondaryBackgroundColor: var(--secondary-backgroundColor);

// success Colors
$successLight: var(--success-light);
$successMain: var(--success-main);
$successDark: var(--success-dark);
$successContrastText: var(--success-contrastText);
$successBackgroundColor: var(--success-backgroundColor);

// error
$errorLight: var(--error-light);
$errorMain: var(--error-main);
$errorDark: var(--error-dark);
$errorContrastText: var(--error-contrastText);
$errorBackgroundColor: var(--error-backgroundColor);

// warning
$warningLight: var(--warning-light);
$warningMain: var(--warning-main);
$warningDark: var(--warning-dark);

// neutral
$neutralLight: var(--neutral-light);
$neutralMain: var(--neutral-main);
$neutralDark: var(--neutral-dark);

// business part colors
$business-background: #ffffff;
$business-borders: #e3e3e3;
$business-heading: #212121;
$business-primary: #3a4d8f;
$business-surface: #ffffff;
$business-text-primary: #656d78;
$business-text-secondary: #727272;