:root {
  // icon
  --icon-main: #455d80;

  // text
  --text-main: #2b4469;
  --text-link: #3268b2;

  // primary
  --primary-light: #769ed1;
  --primary-main: #3268b2;
  --primary-dark: #295494;
  --primary-contrastText: #ffffff;
  --primary-highlight: #d6e7fe;
  --primary-disabledButtonText: #5b8ed5;
  --primary-backgroundColor: #5883be;

  // secondary
  --secondary-light: #769ed1;
  --secondary-main: #3268b2;
  --secondary-dark: #295494;
  --secondary-contrastText: #ffffff;
  --secondary-highlight: #d6e7fe;
  --secondary-disabledButtonText: #5b8ed5;
  --secondary-backgroundColor: #5883be33;

  // success Colors
  --success-light: #67d889;
  --success-main: #32b27c;
  --success-dark: #3ec98e;
  --success-contrastText: #ffffff;
  --success-backgroundColor: #c5efdd33;

  // error
  --error-light: #ff7d6b0d;
  --error-main: #ff7d6b;
  --error-dark: #e25543;
  --error-contrastText: #ffffff;
  --error-backgroundColor: #ff7d6b0d;

  // warning
  --warning-light: #fff8e1;
  --warning-main: #ffe57f;
  --warning-dark: #ffc107;

  // neutral
  --neutral-light: #e6ecf8;
  --neutral-main: #848fa7;
  --neutral-dark: #3b4754;

  // grey
  --grey50: #fafafa;
  --grey100: #f5f5f5;
  --grey200: #eeeeee;
  --grey300: #e0e0e0;
  --grey400: #bdbdbd;
  --grey500: #9e9e9e;
  --grey600: #656d78;
  --grey700: #3b4754;
  --grey800: #424242;
  --grey900: #212121;
}

:export {
  grey50: #fafafa;
  grey100: #f5f5f5;
  grey200: #eeeeee;
  grey300: #e0e0e0;
  grey400: #bdbdbd;
  grey500: #9e9e9e;
  grey600: #656d78;
  grey700: #3b4754;
  grey800: #424242;
  grey900: #212121;

  // default palette colors
  iconMain: #455d80;

  textMain: #2b4469;
  textLink: #3268b2;

  primaryLight: #769ed1;
  primaryMain: #3268b2;
  primaryDark: #295494;
  primaryContrastText: #ffffff;
  primaryHighlight: #d6e7fe;
  primaryDisabledButtonText: #5b8ed5;
  primaryBackgroundColor: #5883be;

  secondaryLight: #769ed1;
  secondaryMain: #3268b2;
  secondaryDark: #295494;
  secondaryContrastText: #ffffff;
  secondaryHighlight: #d6e7fe;
  secondaryDisabledButtonText: #5b8ed5;
  secondaryBackgroundColor: #5883be33;

  successLight: #67d889;
  successMain: #32b27c;
  successDark: #3ec98e;
  successContrastText: #ffffff;
  successBackgroundColor: #c5efdd33;

  errorLight: #ff7d6b0d;
  errorMain: #ff7d6b;
  errorDark: #e25543;
  errorContrastText: #ffffff;
  errorBackgroundColor: #ff7d6b0d;

  warningLight: #fff8e1;
  warningMain: #ffe57f;
  warningDark: #ffc107;

  neutralLight: #e6ecf8;
  neutralMain: #9e9e9e;
  neutralDark: #3b4754;
}
