.title-text {
  font-size: 1.125rem;
  font-family: 'Roboto-Semi-Bold';
  font-weight: 500;
  letter-spacing: 0.05625rem;
}

.title-text-bold {
  font-size: 1.125rem;
}

.text-bold {
  font-size: 1.125rem;
  font-weight: 600;
}

.h4-text-light-secondary {
  font-family: 'Roboto';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.h4-text-neutral {
  color: rgba(35, 49, 68, 0.5);
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 500;
}

.card-placeholder {
  background-color: #f5f6fa;
}

.card-shadow {
  box-shadow: 0px 2px 8px rgba(35, 49, 68, 0.1);
  border-radius: 12px;
}

.selected-card {
  border: 2px solid #90add5;
}

.font-weight-500 {
  font-weight: 500;
}

.h4-text {
  font-family: 'Roboto-Semi-Bold';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.h4-text-link {
  font-family: 'Roboto-Semi-Bold';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: var(--text-link);
}

.h4-text-blue {
  color: #3268b2;
  font-family: 'Roboto-Semi-Bold';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.h4-text-light {
  font-family: 'Roboto';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.h5-text-bold-blue {
  color: #3268b2;
  font-family: 'Roboto-Semi-Bold';
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.h4-text-success {
  color: #32b27c;
  font-weight: 500;
  white-space: 'nowrap';
  overflow: 'hidden';
  text-overflow: 'ellipsis';
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.h5-text-light {
  font-family: 'Roboto';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.334em;
}

.h6-text {
  font-family: 'Roboto';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.04375rem;
}

.body1-text {
  font-family: 'Roboto';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: color 0.2s;
}

.body2-text {
  font-family: 'Roboto';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.334em;
  transition: color 0.2s;
}

.disabled-text {
  color: var(--neutral-main);
}

.error-text {
  color: var(--error-main);
}

.success-text {
  color: var(--success-main);
}

.h6-hypertext {
  cursor: pointer;
  text-decoration: none;
  font-family: 'Roboto';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  color: var(--text-link);
}

.h4-neutral {
  text-decoration: none;
  font-family: 'Roboto';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  color: var(--neutral-main);
}

.secondary-title-text {
  color: #fff;
  font-size: 1.125rem;
  font-family: 'Roboto';
  font-weight: 500;
  letter-spacing: 0.05625rem;
}

.primary-body-text {
  font-family: 'Roboto';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}

.text-hyperlink {
  cursor: pointer;
  color: #3268b2;
  text-align: right;
  font-family: 'Roboto';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.h5-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.5rem;
}

.double-letter-spacing {
  letter-spacing: 0.045rem;
}

.blue-text {
  color: var(--secondary-main);
}

.transparent-blue-text {
  color: #3267b2c0;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.public-form {
  touch-action: none;
  min-height: calc(100vh);
  background-color: #fff;
  width: 100vw;
}

.public-container {
  //touch-action: none;
  height: 100vh;
  background-color: #f5f6fa;
  width: 100vw;
  overflow: auto;
}

.button-primary-overwrite {
  color: #3a4d8f;
  background-color: var(--primary-main) !important;
  color: var(--primary-contrastText) !important;
  border-radius: 8px !important;
  padding: 5px 0 !important;

  &:hover {
    background-color: var(--primary-dark) !important;
    color: var(--primary-contrastText) !important;
    border-radius: 8px;
    padding: 5px 0;
  }

  &:disabled {
    background-color: #DFDFDF !important;
    color: #AAA !important;
  }
}

.button-primary {
  border-radius: 12px;
  height: 48px;
  box-shadow: 0px 2px 8px 0px rgba(78, 108, 203, 0.25);
  width: 100%;
  text-transform: none;
}

.button-primary:disabled {
  background-color: var(--neutral-light);
  color: var(--primary-disabledButtonText);
  box-shadow: none;
}

.button-primary-disabled {
  border-radius: 12px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.26);
  width: 100%;
  font-size: large;

  &:focus,
  &:active,
  &:hover {
    border-radius: 12px;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.26);
    width: 100%;
    font-size: large;
  }
}

.button-warning {
  border-radius: 12px;
  background-color: #ff7d6b;
  width: 100%;
  font-size: large;
  box-shadow: 0px 8px 16px 0px rgba(203, 78, 80, 0.25);

  &:focus,
  &:active,
  &:hover {
    background-color: #ff7d6b;
    outline: none;
  }
}

.button-text {
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.035rem;
}

.button-primary-outlined {
  text-transform: none;
  border-radius: 12px;
  width: 100%;
  border: 1px solid var(--secondary-main);
  color: var(--secondary-main);
  background-color: #fff;
  line-height: 1rem;
  padding: 0.35rem 0;
}

.button-warning-outlined {
  text-transform: none;
  border-radius: 12px;
  width: 100%;
  border: 1px solid #ff7d6b;
  color: #ff7d6b;
  background-color: rgba(255, 125, 107, 0.05);

  &:active,
  &:hover {
    background-color: #ff7d6b;
    font-weight: 200;
    color: white;
  }
}

.button-secondary-outlined {
  border-radius: 12px;
  background-color: white;
  width: 100%;
  color: rgba(35, 49, 68, 0.5);
  border: 1px solid rgba(35, 49, 68, 0.5);
  font-size: large;

  &:focus,
  &:active,
  &:hover {
    background-color: #f5f6fa;
    outline: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.bottom-button {
  margin-left: 0;
  margin-right: 0;
  bottom: 0px;
  position: absolute;
  width: 100%;
}

.public-icon {
  width: 100%;
  height: 100%;
  fill: var(--icon-main);
}

.public-secondary-icon {
  width: 100%;
  height: 100%;
  fill: var(--secondary-main);
}

.public-icon-stroke {
  width: 100%;
  height: 100%;

  path {
    stroke: var(--icon-main);
  }
}

@keyframes input-attention {
  20%, 40%, 80% {
    transform: translateX(-5px);
  }

  30%, 60% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0px);
  }
}
